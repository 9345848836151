import { motion } from 'framer-motion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Github } from '../components/AllSvgs';


const Box = styled(motion.li)`
width: calc(10rem + 15vw);
text-decoration: none;
height: 20rem;
padding: 1rem;
color: ${props => props.theme.text};
border: 2px solid ${props => props.theme.text};
backdrop-filter: blur(2px);
box-shadow:0 0 1rem 0 rgba(0,0,0,0.2);
cursor: pointer;

display: flex;
flex-direction: column;
z-index:5;

&:hover{
    color:${props => props.theme.body};
    background-color: ${props => props.theme.text};
    transition: all 0.3s ease;
}


`

const Image = styled.div`
background-image: ${props => `url(${props.img})`};
width:100%;
height: 50%;
background-size: cover;

border: 1px solid transparent;
background-position: center center;

${Box}:hover &{
    border:1px solid ${props => props.theme.body};
    
}`

const Git = styled(NavLink)`
color: inherit;
text-decoration: none;
${Box}:hover &{
    &>*{
        fill:#fff;
    }
}

`
// const Link = styled(NavLink)`
// background-color: ${props =>props.theme.body};
// color: ${props =>props.theme.text};
// text-decoration: none;
// padding:0.5rem calc(2rem + 2vw);
// border-radius: 0 0 0 50px;
// font-size:calc(1em + 0.5vw);`
const Link = styled(NavLink)`
background-color: #000;
color: #fff;
text-decoration: none;
padding:0.5rem calc(2rem + 2vw);
border-radius: 0 0 0 50px;
font-size:calc(1em + 0.5vw);
${Box}:hover &{
     background-color:#fff;
    color: #000;

}
`

const Footer = styled.footer`
display: flex;
justify-content: space-between;
`
const Title = styled.h3`
color: inherit;
padding: 0.5rem 0;
padding-top:1rem;
font-family: 'Karla',sans-serif;
font-weight:700;
border-bottom: 1px solid ${props => props.theme.text};

${Box}:hover &{
    border-bottom: 1px solid ${props => props.theme.body};

    
}
`
const HashTags = styled.div`
padding: 0.5rem 0;
`
const Tag= styled.span`
padding-right: 0.5rem;
`
const Date = styled.span`
padding:0.5rem 0
`

const Container = styled(motion.div)``;

// Framer motion configuration
const Item = {
    hidden:{
        scale:0
    },
    show:{
        scale:1,
        transition: {
            type: 'spring',
            duration: 0.5
        }
    }
}

const BlogComponent = (props) => {
    const {name, tags, date, imgSrc, link,demo,github} = props.blog;
    return (
        <Container
        variants={Item}
        
        >
            <Box target="_blank" to={{pathname: link}}
        >
            <Image img={imgSrc} />
            <Title>{name}</Title>
            <HashTags>
                {
                    tags.map((t,id) => {
                        return <Tag key={id}>#{t}</Tag>
                    })
                }
            </HashTags>
            <Date>
                {date}
            </Date>
            <Footer>
                <Link to={{pathname: `${demo}`}} target="_blank">
                    Demo
                </Link>
                <Git to={{pathname: `${github}`}} target="_blank">
                    <Github width={30} height={30} />
                </Git>
            </Footer>
        </Box>
        </Container>
    )
}

export default BlogComponent
