// blog data

export const Blogs = [
    {
        id:0,
        name:"BeatBox Pro- Create your Music here",        
        tags:["Javascript","HTML","CSS"],
        date:"13 May, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643654236/GIF/beatbx_gif_njraem.gif",
        demo:"https://beatboxjsapp.netlify.app/",
        github:"https://github.com/VigneshSwamy/BeatBoxJS.git"
    },
    {
        id:1,
        name:"Web application with animation & bubble",
        tags:["Javascript","HTML","CSS"],
        date:"13 May, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643656818/GIF/bubble_xmca9e.gif",
        demo:"https://bubbleapp-web.netlify.app/",
        github:"https://github.com/VigneshSwamy/Webapp_Bubbleblue_1.git"
    },
    {
        id:2,
        name:"Real-time Twitter Streaming application",
        tags:["react","styled-components"],
        date:"5 July, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643658880/GIF/twitter_duhmvj.gif",        
        demo:"https://mytwitter-clone.netlify.app/",
        github:"https://github.com/VigneshSwamy/TwitterApp.git"
    },
    {
        id:3,
        name:"Games for Fun with Code",
        tags:["Express","Javascript","HTML","CSS"],
        date:"6 July, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643661721/GIF/minigames_a4ddkq.gif",
        demo:"https://vanillajs-miniproject.netlify.app/",
        github:"https://github.com/VigneshSwamy/VanillaJS-Mini-project-combo.git"
    },
    {
        id:4,
        name:"Learning Course Tool - Web design",
        tags:["Javascript","HTML","CSS"],
        date:"26 May, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643662591/GIF/learningportal_mdqa9u.gif",
        demo:"https://xypo-learn.netlify.app/",
        github:"https://github.com/VigneshSwamy/Website-creation-xypolearn.git"
    },
    {
        id:5,
        name:"My Idea to Create My-Own-Blog",
        tags:["react","reactJS","web-development"],
        date:"3 Feb, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643677561/GIF/myblog_s38oti.gif",
        demo:"https://firstblogapp.netlify.app/",
        github:"https://github.com/VigneshSwamy/MyBlog.git"
    },
    {
        id:6,
        name:"Guess Number - Game With Me",
        tags:["react","css"],
        date:"5 Jan, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643678519/GIF/guessnum_wk4xtv.gif",
        demo:"https://vigneshswamy.github.io/GuessTheNumber/",
        github:"https://github.com/VigneshSwamy/GuessTheNumber.git"

    },
   
    {
        id:7,
        name:"Lets Rock with My Amazon Clone",
        tags:["react","redux","javascript"],
        date:"16 May, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643680113/GIF/amazon_fabzk5.gif",
        demo:"https://clone-885f4.web.app/",
        github:"https://github.com/VigneshSwamy/Amazon-Clone.git"
    },
    {
        id:8,
        name:"Donation & Charity Portal for Teachers & Students",
        tags:["react","framer-motion","styled-components"],
        date:"26 May, 2021",
        imgSrc:"https://res.cloudinary.com/dspdkirzb/image/upload/v1643685814/GIF/charity_mxlknh.gif",
        demo:"https://locker-room-test.herokuapp.com/",
        github:"https://github.com/VigneshSwamy/Dick-s_Donation_Website_test.git"        
    },
  
] 